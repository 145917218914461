import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const Loader: React.FC = () => {
  return (
    <Flex justifyContent="center" pt="15px">
      <Spinner size="lg" />
    </Flex>
  );
};

export default Loader;
