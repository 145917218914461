import React from 'react';
import liff from '@line/liff';
import { Link, Text } from '@chakra-ui/react';

import { FullHeightLayout } from 'component/layout';

const SessionExpired: React.FC = () => {
  return (
    <FullHeightLayout>
      <Text fontSize="xl" fontWeight="bold">
        セッションの有効切れ
      </Text>
      <Text>
        セッションの有効期限が切れています。
        <br />
        アプリを再起動してください。
      </Text>
      <Link
        fontSize="md"
        textDecoration="underline"
        color="dark"
        onClick={() => liff.closeWindow()}
      >
        アプリを閉じる
      </Link>
    </FullHeightLayout>
  );
};
export default SessionExpired;
