import liff from '@line/liff';
import axios, { AxiosHeaders } from 'axios';

import { BASE_URL } from 'lib/constants';
import {
  BasicFormValueType,
  CardRegisterFormValueType,
  GuestRegisterFormValueType,
  StoreRegisterFormValueType
} from 'type/FormValueType';
import { UtmType } from 'type/UtmType';

interface RequestType {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  hasAccessToken?: boolean;
  data?: object;
  params?: object;
  errLabel: string;
}

export interface PaginationParamType {
  limit: number;
  offset: number;
}

const sendRequest = ({
  url,
  method,
  hasAccessToken,
  data,
  params,
  errLabel
}: RequestType) => {
  const idToken = liff.getIDToken() as string;
  const headers = new AxiosHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
    'Token-Id': idToken
  });

  if (hasAccessToken) {
    const accessToken = liff.getAccessToken() as string;
    headers['Access-Token'] = accessToken;
  }

  return axios({
    url: BASE_URL + url,
    method: method,
    headers: headers,
    data: data,
    params: params
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(`${errLabel}: `, err.response.data);

      if (err.response) {
        const error = err.response.data.errors;
        if (error.includes('IdToken expired.')) {
          localStorage.clear();
          window.location.replace(process.env.PUBLIC_URL + '/session-expired');
        } else {
          return err.response.data;
        }
      } else {
        return false;
      }
    });
};

export const registerGuest = (
  data: GuestRegisterFormValueType &
    StoreRegisterFormValueType &
    Partial<UtmType>
) => {
  return sendRequest({
    url: '/users/guest',
    method: 'POST',
    data: data,
    hasAccessToken: true,
    errLabel: 'ErrorRegisteringUser'
  });
};

export const registerCard = (
  data: CardRegisterFormValueType &
    StoreRegisterFormValueType &
    Partial<UtmType>
) => {
  return sendRequest({
    url: '/users/with_card',
    method: 'POST',
    data: data,
    hasAccessToken: true,
    errLabel: 'ErrorRegisteringUser'
  });
};

export const registerRegular = (
  data: BasicFormValueType & StoreRegisterFormValueType & Partial<UtmType>
) => {
  return sendRequest({
    url: '/users/main',
    method: 'POST',
    data: data,
    hasAccessToken: true,
    errLabel: 'ErrorRegisteringUser'
  });
};

export const upgradeMembership = (data: BasicFormValueType) => {
  return sendRequest({
    url: '/users/current/membership_type',
    method: 'PATCH',
    data: data,
    hasAccessToken: true,
    errLabel: 'ErrorUpgradingMembership'
  });
};

export const getCurrentUser = () => {
  return sendRequest({
    url: '/users/current',
    method: 'GET',
    errLabel: 'ErrorGettingCurrentUser'
  });
};

export const getCurrentPoints = () => {
  return sendRequest({
    url: '/users/current/point/balance',
    method: 'GET',
    errLabel: 'ErrorGettingPointBalance'
  });
};

export const getCurrentBalance = () => {
  return sendRequest({
    url: '/users/current/prepaid/balance',
    method: 'GET',
    errLabel: 'ErrorGettingPrepaidBalance'
  });
};

export const getStoreList = (area_id?: number) => {
  return sendRequest({
    url: '/stores',
    method: 'GET',
    params: {
      area_id: area_id || undefined
    },
    errLabel: 'ErrorGettingStoreList'
  });
};

export const getAreaList = () => {
  return sendRequest({
    url: '/areas',
    method: 'GET',
    errLabel: 'ErrorGettingAreaList'
  });
};

export const getNewsList = () => {
  return sendRequest({
    url: '/notifications',
    method: 'GET',
    errLabel: 'ErrorGettingNewsList'
  });
};

export const getNewsDetails = (id: string) => {
  return sendRequest({
    url: `/notifications/${id}`,
    method: 'GET',
    errLabel: 'ErrorGettingNewsDetails'
  });
};

export const reissueCard = () => {
  return sendRequest({
    url: '/users/current/digital_card',
    method: 'POST',
    hasAccessToken: true,
    errLabel: 'ErrorReissuingCard'
  });
};

export const reconnectCard = (data: CardRegisterFormValueType) => {
  return sendRequest({
    url: '/users/current/actual_card',
    method: 'PATCH',
    data: data,
    hasAccessToken: true,
    errLabel: 'ErrorReconnectingCard'
  });
};

export const updateCurrentUser = (data: BasicFormValueType) => {
  return sendRequest({
    url: '/users/current',
    method: 'PATCH',
    data: data,
    errLabel: 'ErrorUpdatingCurrentUser'
  });
};

export const updateCurrentUserStore = (data: StoreRegisterFormValueType) => {
  return sendRequest({
    url: '/users/current/store',
    method: 'PATCH',
    data: data,
    errLabel: 'ErrorUpdatingStore'
  });
};

export const getPointHistory = ({ limit, offset }: PaginationParamType) => {
  return sendRequest({
    url: '/users/current/point/histories',
    method: 'GET',
    params: {
      limit,
      offset
    },
    errLabel: 'ErrorGettingPointHistory'
  });
};

export const getBalanceHistory = ({ limit, offset }: PaginationParamType) => {
  return sendRequest({
    url: '/users/current/prepaid/histories',
    method: 'GET',
    params: {
      limit,
      offset
    },
    errLabel: 'ErrorGettingBalanceHistory'
  });
};
