import React from 'react';
import ReactDOM from 'react-dom';
import liff from '@line/liff';

import App from './App';
import './assets/style/App.scss';
import reportWebVitals from './reportWebVitals';

liff
  .init({
    liffId: process.env.REACT_APP_LIFF_ID || '',
    withLoginOnExternalBrowser: true
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((e) => {
    console.log('Error Initializing LIFF: ', e.message);
    localStorage.clear();
    liff.login();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
